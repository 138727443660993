import naja from "naja";
import bootstrap from "@/controls/bootstrap";

class TimeSheetExtension {
    initialize(naja) {
        window.addEventListener('DOMContentLoaded', this.load.bind(this));
        naja.addEventListener('complete', this.initRules.bind(this));

        Nette.validators.timeNotInIntervals = function (elem, args, value) {
            const time = value; // The time input value
            for (const interval of args) {
                if (time > interval.start && time < interval.end) {
                    return false;
                }
            }
            return true;
        };

        Nette.validators.timeGreaterThan = function (elem, args, value) {
            const fromInput = document.querySelector(`[name="${args.start}"]`);
            const fromValue = fromInput?.value;
            const toValue = value;

            if (fromValue && toValue) {
                return fromValue < toValue;
            }

            return true;
        };
    }

    addRule(el, rule) {
        // Safely parse the existing rules or initialize as an empty array
        let existing = [];
        try {
            existing = JSON.parse(el.dataset.netteRules || '[]');
        } catch (error) {
            console.error('Invalid netteRules JSON:', el.dataset.netteRules, error);
        }

        // Add the new rule to the array
        existing.push(rule);

        // Update the dataset with the new rules
        el.dataset.netteRules = JSON.stringify(existing);
    }

    initRules(form) {
        const el = document.getElementById('frm-tabs-tabContent-vykazyprace-tabControl-eventForm-eventForm');
        let events = JSON.parse($(el).attr('data-events') || '[]');

        if (!(Array.isArray(events) && events.length > 0)) {
            return; // Exit if no events to validate against
        }

        const edit = el.querySelector('[name="edit"]');
        const editId = parseInt(edit.value, 10); // Get the ID to edit and convert to an integer
        events = events.filter(event => event.id !== editId);
        if (events.length <= 0) {
            return;
        }

        const start = el.querySelector('[name="from"]');
        const end = el.querySelector('[name="to"]');

        // Construct the rule as an object
        const rule = {
            op: ':timeNotInIntervals',
            msg: 'Čas se překrývá s jinou událostí',
            arg: events,
        };

        // Add rules to the "from" and "to" fields
        this.addRule(start, rule);
        this.addRule(end, rule);

        const netteRules = JSON.parse(end.dataset.netteRules || '[]');
        netteRules.push({
            op: ':timeGreaterThan',
            msg: 'Čas "Do" musí být větší než čas "Od".',
            arg: { start: 'from' }, // Name of the "from" field
        });
        end.dataset.netteRules = JSON.stringify(netteRules);

        start.addEventListener('change', () => {
            // Trigger validation on "to" field when "from" changes
            Nette.validateControl(end);
        });
    }

    load()
    {
        $(document).on('click', 'a.scroll-to', function(e){
            e.preventDefault();
            const targetId = this.getAttribute('href').replace('#', '');
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        });
        $(document).on('click', 'a.scroll-top', function(e){
            e.preventDefault();
            $('.app-body').animate({ scrollTop: 0 }, 'fast');
        });
        $(document).on('change', '#snippet-tabs-tabContent-vykazyprace-tabControl-modal input[name=ticket]', function() {
            const caption = $(this).parent().find('.iframe-input-caption').html();
            $(this).closest('form').find('input[name=event]').val(caption);
        });

        $(document).on('change', '#snippet-tabs-tabContent-vykazyprace-tabControl-modal select[name=category]', function() {
            const selectedOption = $(this).find(':selected');
            const from = $(this).closest('form').find('input[name=from]');
            const to = $(this).closest('form').find('input[name=to]');
            const event = $(this).closest('form').find('input[name=event]');

            if (from.val().length <= 0) {
                from.val(selectedOption.data('start')).change();
            }
            if (to.val().length <= 0) {
                to.val(selectedOption.data('end')).change();
            }
            if (event.val().length <= 0) {
                event.val(selectedOption.html().trim()).change().focus();
            }
        });
    }
}

export default TimeSheetExtension;
