class CheckboxSelectAll {
    initialize(naja) {
        // Immediately initialize if DOM is already loaded
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            this.init();
        } else {
            document.addEventListener('DOMContentLoaded', this.init.bind(this));
        }

        // Add listeners for Naja events
        naja.addEventListener('interaction', this.init.bind(this));
        naja.addEventListener('complete', this.init.bind(this));
    }

    init(event) {
        const checkboxes = document.querySelectorAll('.checkbox-select-all');
        checkboxes.forEach(filter => {
            const triggers = filter.querySelectorAll('.checkbox-select-all-trigger');
            triggers.forEach(radio => {
                radio.addEventListener('change', this.onCheckboxChange.bind(this));
            });
        });
    }

    onCheckboxChange(event) {
        const checkbox = event.target;
        const filter = checkbox.closest('.checkbox-select-all');
        const checkboxes = filter.querySelectorAll('.checkbox-select-all-item');
        checkboxes.forEach(checkboxItem => {
            checkboxItem.checked = checkbox.checked;
        });
    }
}

export default CheckboxSelectAll;
