import naja from "naja";
import Sortable from 'sortablejs';

document.addEventListener('DOMContentLoaded', () => {
    const initAcl = $('.initAcl');
    if (!initAcl.length) {
        return;
    }

    const acl = new Acl();
    acl.init();

    naja.addEventListener('complete', () => {
        acl.init();
    });
});

class Acl {
    init() {
        this.initSortableAclRows();
    }

    initSortableAclRows() {
        this.initSortableAcl(document.querySelectorAll(".acl-sortable"));
        this.initSortableAcl(document.querySelectorAll(".acl-sortable-children"));
    }

    initSortableAcl(containers) {

        for (var i = 0; i < containers.length; i++) {
            new Sortable(containers[i], {
                group: {
                    name: "sortable-list-" + i,
                    pull: true,
                    put: true,
                },
                animation: 250,
                forceFallback: true,
                draggable: '.datagrid2-tree-item',
                handle: '.handle-sort',
                onMove(evt) {
                    if(evt.from !== evt.to) {
                        return false
                    }
                },
                onEnd: function (/**Event*/ evt) {
                    const itemEl = evt.item;

                    let url = $('.initAcl').data('move').replace('=-1', '=' + itemEl.dataset.id).replace('=-2', '=' + evt.newIndex);
                    naja.makeRequest('GET', url);
                }
            });
        }
    }

}
