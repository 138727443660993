// najdeme na stránce všechny podřízené selectboxy

class DependentSelect {
    init() {
        this.initComponent();
    }

    initComponent() {
        document.querySelectorAll('select[data-depends]').forEach((childSelect) => {
            let parentSelect = childSelect.form[childSelect.dataset.depends]; // nadřízený <select>
            let url = childSelect.dataset.url; // atribut data-url
            let items = JSON.parse(childSelect.dataset.items || 'null'); // atribut data-items

            // Handling Select2 change event
            if ($(parentSelect).hasClass('select2-init')) {
                $(parentSelect).on('select2:select', () => {
                    this.triggerChangeEvent(childSelect, parentSelect, items, url);
                });
            }

            parentSelect.addEventListener('change', () => {
                this.triggerChangeEvent(childSelect, parentSelect, items, url);
            });
            this.triggerChangeEvent(childSelect, parentSelect, items, url);
        });
    }

    triggerChangeEvent(childSelect, parentSelect, items, url) {
        if (items) {
            this.updateSelect(childSelect, items[parentSelect.value]);
        }

        if (url) {
            fetch(url.replace(encodeURIComponent('#'), encodeURIComponent(parentSelect.value)))
                .then((response) => response.json())
                .then((data) => this.updateSelect(childSelect, data));
        }
    }

    addPrompt(select)
    {
        let prompt = $(select).attr('data-prompt') ?? null;
        if (typeof prompt === 'string' && prompt.trim() !== '') {
            let promptOption = document.createElement('option');
            promptOption.setAttribute('value', '0');
            promptOption.innerText = prompt;
            select.appendChild(promptOption);
        }
    }

    updateSelect(select, items) {
        select.innerHTML = '';
        this.addPrompt(select)

        let defaultValue = items['#default#'] ?? null;
        defaultValue = $(select).attr('data-default-value') ?? defaultValue;
        delete items['#default#'];

        let value = $(select).attr('data-value') ?? null;

        for (let id in items) {
            let el = document.createElement('option');
            el.setAttribute('value', id);
            if (id === value || (value === null && id === defaultValue)) {
                el.setAttribute('selected', 'selected');
            }
            el.innerText = items[id];
            select.appendChild(el);
        }

        let disabledIfEmpty = $(select).attr('data-disabled-if-empty') ?? false;
        if (disabledIfEmpty !== false) {
            select.disabled = items.length <= 0;
        }

        let disabled = $(select).attr('data-disabled') ?? false;
        if (disabled !== false) {
            select.disabled = true;
        }
    }

    initialize(naja) {
        naja.addEventListener('complete', this.initComponent.bind(this));
        naja.addEventListener('interaction', this.initComponent.bind(this));
        window.addEventListener('DOMContentLoaded', this.initComponent.bind(this));

    }
}

export default DependentSelect;
