/* global naja, $, intervalFunction */
class IframeInputExtension {
    selectors = {
        container: '.iframe-input-container',
        popup: '.iframe-input-popup',
        content: '.iframe-input-content',
        caption: '.iframe-input-caption',
        option: '.iframe-input-option',
        input: '.iframe-input',
        group: '.iframe-input-group',
        attributes: {
            caption: 'data-iframe-input-caption',
            value: 'data-iframe-input-value'
        },
        type: {
            select: '.iframe-input-type-select',
            input: '.iframe-input-type-input'
        }
    }
    initialize(naja) {
        window.addEventListener('DOMContentLoaded', this.init.bind(this));
        naja.addEventListener('complete', this.init.bind(this));
        naja.uiHandler.addEventListener('interaction', this.init.bind(this));
    }
    init() {
        this.addStyles();
        $(this.selectors.container).each((index, container) => {
            this.initIframeInput(container);
        });
    }

    initIframeInput(container) {
        const $container = $(container);
        $container.find(this.selectors.popup).off();
        $container.find(this.selectors.popup).on('load', (event) => {
            this.registerPopupDocumentEvents(container, $(event.target).contents());
        });
        $container.find(this.selectors.group).off();
        $container.find(this.selectors.group).on('click', (event) => {
            this.toggle($container);
        });
        $(document).on('click', (event) => {
            if (!$(event.target).closest(this.selectors.container).length) {
                $(this.selectors.container).each((index, container) => {
                    this.close(container);
                });
            }
        });

        $container.find(this.selectors.input).on('keyup', (event) => {
            if (event.keyCode === 13 || event.keyCode === 27) {
                this.close($container);
            }
        });
    }


    registerPopupDocumentEvents(container, document) {
        const optionSelector = this.selectors.option;
        $(document).on('click', optionSelector, (event) => {
            const $target = $(event.target).closest(optionSelector);
            if ($target.length) {
                const caption = $target.attr(this.selectors.attributes.caption);
                const value = $target.attr(this.selectors.attributes.value);
                this.optionClicked(container, caption, value);
            }
        });
    }

    optionClicked(container, caption, value) {
        return this.isSelect(container)
            ? this.onSelectOptionClicked(container, caption, value)
            : this.onInputOptionClicked(container, value);
    }

    onSelectOptionClicked(container, caption, value) {
        const $input = $(container).find(this.selectors.input);
        const $caption = $(container).find(this.selectors.caption);
        $input.val(value);
        $caption.html(caption);
        this.close(container);
        $input.trigger('change');
    }

    onInputOptionClicked(container, value) {
        const $input = $(container).find(this.selectors.input);
        $input.val(value);
        this.close(container);
        $input.trigger('change');
    }

    isSelect(container) {
        return $(container).find(this.selectors.type.select).length > 0;
    }

    isInput(container) {
        return $(container).find(this.selectors.type.input).length > 0;
    }

    getPosition(container) {
        //return 'iframe-input-popup--above';
        //return 'iframe-input-popup--below';
        const $input = $(container).find(this.selectors.group);
        if ($input.length === 0) {
            return 'iframe-input-popup--below';
        }
        const viewportHeight = $(window).height();
        const inputOffset = $(container).offset().top;
        const $popup = $(container).find(this.selectors.popup);
        return (inputOffset + $popup.height() > viewportHeight)
            ? 'iframe-input-popup--above'
            : 'iframe-input-popup--below';
    }

    isOpen(container) {
        return $(container).hasClass('iframe-input--open');
    }

    toggle(container) {
        if (this.isOpen(container)) {
            this.close(container);
        } else {
            this.open(container);
        }
    }

    open(container) {
        $(container).addClass('iframe-input--open');
        this.addStyles(container);
    }
    close(container) {
        $(container).removeClass('iframe-input--open');
        this.addStyles(container);
    }

    addStyles(container) {
        const $content = $(container).find(this.selectors.content);
        const $popup = $(container).find(this.selectors.popup);
        const $input = $(container).find(this.selectors.group);

        const dropdownPosition = this.getPosition(container);
        $content.removeClass('iframe-input-popup--above');
        $content.removeClass('iframe-input-popup--below');
        $content.addClass(dropdownPosition);

        $input.css('top', 0);
        if (this.isOpen(container) && dropdownPosition === 'iframe-input-popup--above') {
            $input.css('top', $popup.height());
        }
    }
}

export default IframeInputExtension;
