class InvoiceForm {
    constructor() {
        // Inicializace při vytvoření instance
        this.initInvoiceDetail();
        this.initInvoiceItem();
        this.initInvoiceItemAutocomplete();

        this.lastQuery = ''; 
        this.activeRequest = null; 
        this.isLoading = false; 
        this.debounceTimer = null; 
    }

    initInvoiceDetail() {
        // Načtení inputů pro datum a maturity
        const dateIssueInput = document.getElementById('date-issue');
        const maturityInput = document.getElementById('maturity');
        const dateMaturityInput = document.getElementById('date-maturity');

        if (dateIssueInput && maturityInput && dateMaturityInput) {
            maturityInput.addEventListener('input', () => {
                this.updateDateMaturity(dateIssueInput, maturityInput, dateMaturityInput);
            });

            dateMaturityInput.addEventListener('input', () => {
                this.updateMaturity(dateIssueInput, maturityInput, dateMaturityInput);
            });
        }
    }

    initInvoiceItem() {
        // Načtení inputů pro položky faktury
        const invoiceItemCount = document.getElementById('invoice-item-count');
        const invoiceItemTaxRate = document.getElementById('invoice-item-tax-rate');
        const invoiceItemPrice = document.getElementById('invoice-item-price');
        const invoiceItemDiscount = document.getElementById('invoice-item-discount');
        const invoiceItemPriceBase = document.getElementById('invoice-item-price-base');
        const invoiceItemPriceTotal = document.getElementById('invoice-item-price-total');

        if (invoiceItemCount && invoiceItemTaxRate && invoiceItemPrice && invoiceItemPriceBase && invoiceItemPriceTotal) {
            invoiceItemCount.addEventListener('input', () => {
                this.updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal);
            });

            invoiceItemTaxRate.addEventListener('input', () => {
                this.updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal);
            });

            invoiceItemPrice.addEventListener('input', () => {
                this.updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal);
            });

            if (invoiceItemDiscount) {
                invoiceItemDiscount.addEventListener('input', () => {
                    this.updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal);
                });
            }
        }
    }

    initInvoiceItemAutocomplete() {
        const input = document.getElementById('invoice-item-name');

        if (input) {
            let suggestionsContainer = document.getElementById('suggestions-container');
            if (!suggestionsContainer) {
                suggestionsContainer = document.createElement('div');
                suggestionsContainer.id = 'suggestions-container';
                suggestionsContainer.classList.add('list-group');
                suggestionsContainer.style.position = 'absolute';  
                suggestionsContainer.style.zIndex = '9999';  
                suggestionsContainer.style.width = `450px`;  
                suggestionsContainer.style.height = `35vh`;  

                document.body.appendChild(suggestionsContainer);
            }

            const loadSuggestions = () => {
                clearTimeout(this.debounceTimer);  
                this.debounceTimer = setTimeout(() => {
                    const query = input.value;
                    const provide = input.getAttribute('data-provide');

                    if (query.length > 2 && query !== this.lastQuery && !this.isLoading) {
                        this.lastQuery = query;
                        this.isLoading = true;

                        // Zrušení předchozího requestu, pokud existuje
                        if (this.activeRequest) {
                            this.activeRequest.abort(); 
                        }

                        input.classList.add('loading');

                        const inputRect = input.getBoundingClientRect();
                        suggestionsContainer.style.top = `${inputRect.bottom + window.scrollY}px`;
                        suggestionsContainer.style.left = `${inputRect.left + window.scrollX}px`;
                        suggestionsContainer.style.width = `${inputRect.width * 4}px`;

                        this.activeRequest = naja.makeRequest('POST', provide, { query: query }, { history: false })
                            .then(response => {
                                this.displaySuggestions(response, query);
                                input.classList.remove('loading');
                                this.isLoading = false;
                                this.activeRequest = null; 
                            })
                            .catch(() => {
                                input.classList.remove('loading');
                                this.isLoading = false;
                                this.activeRequest = null; 
                            });
                    }
                }, 300);  // Debounce čas (300 ms)
            };

            input.addEventListener('keyup', loadSuggestions);

            input.addEventListener('blur', () => {
                setTimeout(() => {
                    suggestionsContainer.innerHTML = '';
                    suggestionsContainer.style.display = 'none';
                }, 200);
            });
        }
    }

    displaySuggestions(suggestions, query) {
        const suggestionsContainer = document.getElementById('suggestions-container');
        suggestionsContainer.innerHTML = '';  
        suggestionsContainer.style.display = 'flex';

        for (let id in suggestions) {
            if (suggestions.hasOwnProperty(id)) {
                const productName = suggestions[id];
                const productId = id;
                const highlighted = this.highlightMatch(productName, query);

                const item = document.createElement('a');
                item.classList.add('list-group-item', 'list-group-item-action');
                item.innerHTML = highlighted;

                item.addEventListener('click', () => {
                    document.getElementById('invoice-item-name').value = productName;
                    document.getElementById('invoice-item-store-card').value = productId;
                    document.getElementById('invoice-item_add-store-card').value = productId;
                    suggestionsContainer.innerHTML = ''; 
                    suggestionsContainer.style.display = 'none';
                });

                suggestionsContainer.appendChild(item);
                suggestionsContainer.style.display = 'flex';
            }
        }
    }

    highlightMatch(text, query) {
        const regex = new RegExp(`(${query})`, 'gi');
        return text.replace(regex, '<strong>$1</strong>'); 
    }

    updateDateMaturity(dateIssueInput, maturityInput, dateMaturityInput) {
        const dateIssue = new Date(dateIssueInput.value);
        const maturity = parseInt(maturityInput.value, 10);

        if (!isNaN(dateIssue.getTime()) && !isNaN(maturity)) {
            const newDateMaturity = new Date(dateIssue);
            newDateMaturity.setDate(dateIssue.getDate() + maturity);
            dateMaturityInput.value = newDateMaturity.toISOString().split('T')[0];
        }
    }

    updateMaturity(dateIssueInput, maturityInput, dateMaturityInput) {
        const dateIssue = new Date(dateIssueInput.value);
        const dateMaturity = new Date(dateMaturityInput.value);

        if (!isNaN(dateIssue.getTime()) && !isNaN(dateMaturity.getTime())) {
            const diffTime = dateMaturity - dateIssue;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            maturityInput.value = diffDays;
        }
    }

    updateInvoiceItemPrice(invoiceItemCount, invoiceItemTaxRate, invoiceItemPrice, invoiceItemDiscount, invoiceItemPriceBase, invoiceItemPriceTotal) {
        const itemCount = parseInt(invoiceItemCount.value, 10);
        const taxRate = parseInt(invoiceItemTaxRate.value, 10);
        const price = parseFloat(invoiceItemPrice.value);
        const discount = invoiceItemDiscount ? parseFloat(invoiceItemDiscount.value) || 0 : 0;

        if (!isNaN(itemCount) && !isNaN(taxRate) && !isNaN(price)) {
            const priceBase = itemCount * price;
            const priceTotal = priceBase * (1 + taxRate / 100) * (1 - discount / 100);

            invoiceItemPriceBase.value = priceBase.toFixed(2);
            invoiceItemPriceTotal.value = priceTotal.toFixed(2);
        }
    }

    static initialize() {
        // Vytvoření instance třídy pouze pokud jsou v DOM potřebné elementy
        new InvoiceForm();
    }
}

// Inicializace při načtení DOM
document.addEventListener('DOMContentLoaded', () => {
    InvoiceForm.initialize();
});

// Připojení na události Naja pro AJAXové operace
naja.addEventListener('complete', () => {
    InvoiceForm.initialize();
});

export default InvoiceForm;