import { Tooltip } from 'bootstrap';
import { Popover } from 'bootstrap';

class BootstrapExtension {
    initialize(naja) {
        window.addEventListener('DOMContentLoaded', this.initPopover.bind(this));
        naja.addEventListener('complete', this.initPopover.bind(this));
    }

    hidePopover(){
        $('.tooltip').remove();
    }

    initPopover() {

        this.hidePopover();

        // Tooltip
        var tooltipTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        var tooltipList = tooltipTriggerList.map(
            function (tooltipTriggerEl) {
                return new Tooltip(tooltipTriggerEl, {trigger : 'hover', 'html': true});
            }
        );

        // Popover
        var popoverTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="popover"]')
        );
        var popoverList = popoverTriggerList.map(
            function (popoverTriggerEl) {
                return new Popover(popoverTriggerEl);
            }
        );

    }
}

export default BootstrapExtension;
