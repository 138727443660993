import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import csLocale from '@fullcalendar/core/locales/cs';
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';


import naja from "naja";

document.addEventListener('DOMContentLoaded', () => {
    const calendar = new FullCalendar();
    calendar.init();

    naja.addEventListener('complete', () => {
        calendar.init();
    });

});

class FullCalendar {
    init() {
        const calendarElements = document.querySelectorAll('.calendar');
        let self = this;
        calendarElements.forEach(calendarEl => {
            if (calendarEl.getAttribute('data-loaded') === 'true') {
                return;
            }
            calendarEl.setAttribute('data-loaded', 'true');
            const eventsSourceLink = calendarEl.getAttribute('data-eventsSourceLink');
            if (eventsSourceLink === null)
            {
                const message = 'Missing attribute data-eventsSourceLink on calendar element!';
                console.error(message, calendarEl);
                calendarEl.innerHTML = "<div class='alert alert-danger'>Chybná konfigurace kalendáře, více informací v JavaScript konzoli.</div>"
                return;
            }
            const calendar = new Calendar(calendarEl, {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrap5Plugin],
                initialView: 'dayGridMonth',
                locale: csLocale,
                themeSystem: 'bootstrap5',
                editable: true,
                selectable: true,
                height: 'auto',
                contentHeight: 'auto',
                expandRows: true,
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridFourDay,timeGridDay,listMonth'
                },
                views: {
                    timeGridFourDay: {
                        type: 'timeGrid',
                        duration: {days: 4},
                        buttonText: '4 dny'
                    }
                },
                eventSources: {
                  url: eventsSourceLink
                },
                eventsSet: function() {
                    document.querySelectorAll('.fc-toolbar button').forEach(function(button) {
                        button.setAttribute('data-loading-indicator', 'false');
                    });
                },
                dateClick: function(info) {
                    self.showDay(this.el, info.dateStr);
                },
                eventClick: function(info) {
                    self.showEventDetail(this.el, info.event.id);
                },
                eventDrop: function (info) {
                    self.handleEventMove(this.el, info.event, info.revert);
                }
            });
            calendar.render();
        });
    }

    showDay(calendarEl, date) {
        let link = calendarEl.getAttribute('data-showDayLink');
        if (link === null)
        {
            console.error('Missing attribute data-showDayLink on calendar element!');
            return;
        }

        if (!link.includes('--date--'))
        {
            console.error('data-showDayLink on calendar element must contain placeholder --date--, which will be replaced with selected date!');
            return;
        }

        link = link.replace('--date--', date);
        naja.makeRequest('GET', link);
    }

    showEventDetail(calendarEl, eventId) {
        let link = calendarEl.getAttribute('data-showEventDetailLink');
        if (link === null)
        {
            console.error('Missing attribute data-showEventDetailLink on calendar element!');
            return;
        }

        if (!link.includes('--id--'))
        {
            console.error('data-showEventDetailLink on calendar element must contain placeholder --id--, which will be replaced with selected eventId!');
            return;
        }

        link = link.replace('--id--', eventId);
        naja.makeRequest(
            'GET', link, {
                isModal: true
            }
        );
    }

    handleEventMove(calendarEl, event, revertMoveEvent) {
        let link = calendarEl.getAttribute('data-moveEventLink');

        if (link === null) {
            console.error('Missing attribute updateEventLink in event data or extendedProps is not defined!');
            revertMoveEvent();
            return;
        }

        if (!link.includes('--id--') || !link.includes('--from--') || !link.includes('--to--')) {
            console.error('updateEventLink must contain placeholders --id-- and --date-- to be replaced with event id and new date!');
            revertMoveEvent();
            return;
        }

        link = link.replace('--id--', event.id)
            .replace('--from--', event.start.toISOString())
            .replace('--to--', event.end.toISOString());

        naja.makeRequest('GET', link, null,{ history: false }).then(function (response) {
            if (response.result !== 'success') {
                console.log('Event move failed, reverting...');
                revertMoveEvent(); // Revert the event if the result is not 'success'
            }
        }).catch(function (error) {
            console.error('Event move failed due to error', error);
            revertMoveEvent(); // Revert the event in case of an error
            alert('Přesunutí události selhalo. Obnovte stránku a zkuste to prosím znovu.');
        });
    }
}
