import naja from "naja";

class FormsExtension {
    initialize(naja)
    {
        window.addEventListener('DOMContentLoaded', this.reloadForm.bind(this));
         naja.snippetHandler.addEventListener('afterUpdate', this.reloadForm.bind(this));
             /*
                 initialize(naja)
    {
        naja.uiHandler.addEventListener('interaction', this.handleInteraction.bind(this));
        naja.addEventListener('complete', this.openModal.bind(this));
    }

              */
    }

    submitForm(input)
    {
        const form = $(input).closest('form');
        if ($(form).hasClass('ajax'))
        {
            if ($(input).hasClass('on-change-skip-validation'))
            {
                let values = new FormData(form[0]);
                values.append('onChangeSkipValidation', 'onChangeSkipValidation');
                naja.makeRequest('POST', form[0].action, values).then((data) => {
                    window.Nette.init();
                });
            } else {
                naja.uiHandler.submitForm(form[0]);
            }
        } else {
            $(form).submit();
        }
    }

    reloadForm()
    {
        const self = this;
        $(document).on('change', '.on-change-reload-form', function(event) {
            if ($(this).attr('data-autocomplete-provide')) {
                return;
            }
            self.submitForm(this);
        });

        $(document).on('keyup', 'input[data-autocomplete-provide]', function (event) {
            const $input = $(this);
            const link = $input.attr('data-autocomplete-provide');
            const query = ($input.val() || '').trim();
            const $wrapper = $input.closest('.input-wrapper');
            const $label = $wrapper.find('.label');
            $input.removeAttr('autocomplete');

            if (query.length < 3) {
                $('.autocomplete-box').remove();
                return;
            }

            $.ajax({
                type: 'POST',
                url: link,
                data: { query: query },
                success: function (data) {
                    $('.autocomplete-box').remove();

                    if (data.length === 0) return;

                    const $box = $('<div class="autocomplete-box"></div>').css({
                        position: 'absolute',
                        zIndex: 10000,
                        background: '#fff',
                        border: '1px solid #ccc',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: 0,
                    });

                    data.forEach(item => {
                        const isHtml = /<\/?[a-z][\s\S]*>/i.test(item); // Check if item is HTML
                        const value = isHtml ? $(item).data('value') || item : item;
                        const label = isHtml ? $(item).data('label') || item : item;

                        $('<div class="autocomplete-option"></div>')
                            .html(item)
                            .css({
                                padding: '8px',
                                cursor: 'pointer',
                            })
                            .hover(
                                function () { $(this).css('background', '#f0f0f0'); },
                                function () { $(this).css('background', '#fff'); }
                            )
                            .on('mousedown', function () {
                                $input.val(value).focus(); // Keep focus on input
                                $label.text(label).show();
                                $box.remove();
                                if ($input.hasClass('on-change-reload-form')) {
                                    self.submitForm($input);
                                }
                            })
                            .appendTo($box);
                    });

                    $('body').append($box);

                    const offset = $input.offset();
                    $box.css({
                        top: offset.top + $input.outerHeight(),
                        left: offset.left,
                        width: $input.outerWidth(),
                    });
                },
                error: function (xhr, status, error) {
                    console.error('Error:', error);
                }
            });
        });

        $('input[data-autocomplete-provide]').each(function (input){
            const $input = $(this);
            if (!$input.parent().hasClass('input-wrapper')) {
                $input.wrap('<div class="input-wrapper"></div>')
                    .before('<div class="label" style="display: none; position: absolute; background: white; left:30px; top:23px;"></div>');
                $input.focus();
            }

            const $wrapper = $input.closest('.input-wrapper');
            const $label = $wrapper.find('.label');

            const value = $input.data('value');
            const label = $input.data('label');
            $input.val(value).focus(); // Keep focus on input
            $label.text(label).show();

            $wrapper.on('click', function () {
                $input.val('');
                $label.hide();
            });
        });

        $(document).on('blur', 'input[data-autocomplete-provide]', function () {
            setTimeout(() => {
                $('.autocomplete-box').remove();
            }, 100);
        });

        $(document).on('click', function (event) {
            if (!$(event.target).closest('input[data-autocomplete-provide], .autocomplete-box').length) {
                $('.autocomplete-box').remove();
            }
        });


    }
}

export default FormsExtension;
