import { Modal } from 'bootstrap';

class ConfirmDialogExtension {
    constructor() {
        this.confirmDialogShown = false;
    }

    initialize(naja) {
        naja.uiHandler.addEventListener(
            'interaction', (event) => {
                const { element } = event.detail;
                const question = element.dataset.confirm;
                if (question !== undefined && !this.confirmDialogShown) {
                    event.preventDefault();
                    this.showConfirmDialog(question, () => {
                        if ((element.tagName === 'INPUT' || element.tagName === 'BUTTON') && element.type === 'submit' && element.form) {
                            this.confirmDialogShown = true;
                            element.click();
                        } else {
                            naja.makeRequest('GET', element.href, null, { history: false });
                        }
                    });
                }
            }
        );
    }

    showConfirmDialog(question, yesCallback) {
        question = question || 'Opravdu chcete provést akci?';
        const modal = document.createElement('div');
        modal.classList.add('modal', 'fade');
        modal.tabIndex = -1;
        modal.role = 'dialog';
        modal.innerHTML = `
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">${question}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary">Ne</button>
                    <button type="button" class="btn btn-primary">Ano, vím co dělám</button>
                </div>
            </div>
        </div>
        `;

        document.body.appendChild(modal);

        const bootstrapModal = new Modal(modal);
        modal.querySelector('.btn-primary').addEventListener(
            'click', () => {
                yesCallback();
                bootstrapModal.hide();
            }
        );
        modal.querySelector('.btn-secondary').addEventListener(
            'click', () => {
                bootstrapModal.hide();
            }
        );

        bootstrapModal.show();
    }
}

export default ConfirmDialogExtension;
