import select2 from 'select2';
import './../../../node_modules/select2/dist/css/select2.css';
import './../../../node_modules/select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';

/* global naja, $, intervalFunction */
class Select2Extension {
    init()
    {
        this.initSelect2();
    }
    initSelect2()
    {
        $('.select2-init').each(
            function () {
                if (!$(this).hasClass('select2-hidden-accessible')) {
                    const options = {
                        theme: 'bootstrap-5',
                    };

                    if ($(this).closest('.modal-content').length){
                        options.dropdownParent = $(this).closest('.modal-content');
                    }

                    $(this).select2(
                        options
                    );

                    $(this).on('select2:open', function () {
                        const searchField = $('.select2-container--open .select2-search__field');
                        if (searchField.length) {
                            searchField[0].focus();
                        }
                    });
                }
            }
        );
    }
    initialize(naja)
    {
        naja.addEventListener('complete', this.initSelect2.bind(this));
    }
}

export default Select2Extension;
