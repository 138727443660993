import initializeFileUploadControl from "../../libs/file-upload-control/src/assets/js/index.js";

class FileUploadExtension {
    initialize(naja) {
        window.addEventListener('DOMContentLoaded', this.init.bind(this));
    }
    init() {
        initializeFileUploadControl(window.Nette);
        $(document).on('change', 'form#upload_document input[name=description]', this.updateState.bind(this));
        this.updateState();
        window.Nette.initOnLoad();
    }
    updateState()
    {
        let specify = $('form#upload_document #description-other');
        $("#document_upload_other_description").prop("disabled", !specify.is(':checked'));
    }
}

export default FileUploadExtension;
