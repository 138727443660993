import naja from "naja";
document.addEventListener('DOMContentLoaded', () => {
    initStoreDevice();
    initCatalog();
    initDocuments();
    initItems();
});
naja.addEventListener('complete', () => {
    initItems();
});

function initStoreDevice() {
    const isStoreDevice = $('#frm-deviceTabs-tabContent-zakladniinformace-tabControl-editForm');
    if (!isStoreDevice.length) {
        return;
    }

    const storeDevice = new StoreDevice();
    storeDevice.init();

    naja.addEventListener('complete', () => {
        storeDevice.init();
    });
}

function initCatalog() {
    const isCatalog = $('#frm-catalogEditTabs-tabContent-zakladniinformace-tabControl-infoForm');
    if (!isCatalog.length) {
        return;
    }

    const catalog = new Catalog();
    catalog.init();

    naja.addEventListener('complete', () => {
        catalog.init();
    });
}

function initDocuments() {
    const isDocuments = $('#snippet-catalogEditTabs-tabContent-doklady-tabControl-grid-grid-grid');
    if (!isDocuments.length) {
        return;
    }

    const documents = new Documents();
    documents.init();

    naja.addEventListener('complete', () => {
        documents.init();
    });
}

function initItems() {
    const isItems = $('#snippet-catalogEditTabs-tabContent-polozkynaskladech-tab');
    if (!isItems.length) {
        return;
    }

    const items = new Items();
    items.init();

    naja.addEventListener('complete', () => {
        items.init();
    });
}


class StoreDevice {
    purchaseDateId = 'frm-deviceTabs-tabContent-zakladniinformace-tabControl-editForm-purchaseDate';
    serialSelectId = 'frm-deviceTabs-tabContent-zakladniinformace-tabControl-editForm-serialNumberSelect';
    serialId = 'frm-deviceTabs-tabContent-zakladniinformace-tabControl-editForm-serialNumber';

    init() {
        $('#'+this.purchaseDateId).change(this.setPurchaseDate.bind(this));

        const serial = $('#' + this.serialSelectId);
        serial.change(this.setPurchaseDate.bind(this));
        serial.prop('selectedIndex', 0); // Select the first option
        serial.trigger('change');        // Trigger the change event
             // Attach the event handler

        $('input[disabled]').each(function() {
            $(this).removeAttr('disabled').attr('readonly', true);
        });
    }
    setPurchaseDate(event)
    {
        const select = $(event.target);
        const selectedOption = select.find(':selected');
        const price = selectedOption.data('price');
        const date = selectedOption.data('date');
        select.closest('form').find('input[name=datePurchase]').val(date);
        select.closest('form').find('input[name=price]').val(price);
        if (select.attr('id') == this.serialSelectId) {
            $('#'+this.serialId).val(select.val());
        }
        console.log({ price, date });
    }
}

class Catalog {
    init() {
        const catalog = this;
        $('select[name=measureID]').on('change', catalog.updateDecimal.bind(catalog));
        $("input[name=actionActive]").on("change", function() {
            catalog.setAction(!$(this).prop("checked"));
            if ($(this).prop("checked")) {
                $("select[name=actionTerminationType]").change();
            }
        }).change();

        // při změně typu ukončení akce
        $("[name=actionTerminationType]").bind("change", function() {
            if ($("[name=actionTerminationType]").val() == 1) {
                $("[name=actionStartDate]").prop("disabled", false).closest("div").show();
                $("[name=actionTerminationDate]").prop("disabled", false).closest("div").show();
                $("[name=actionTerminationAmount]").prop("disabled", true).closest("div").hide();
            } else {
                $("[name=actionStartDate]").prop("disabled", true).closest("div").hide();
                $("[name=actionTerminationDate]").prop("disabled", true).closest("div").hide();
                $("[name=actionTerminationAmount]").prop("disabled", false).closest("div").show();
            }
        }).change();

        $("[name=storeCardType]").on("change", function() {
            catalog.setCardType($(this).val());
        }).change();

        $("[name=actionActive]").change();
        $("[name=actionStartDate]").datepicker();
        $("[name=actionTerminationDate]").datepicker();

        $("[name=priceType]").on("change", catalog.setPriceType.bind(catalog)).change();
        catalog.updateDecimal();
        catalog.bindPriceEvents();
    }

    setCardType(type) {
        let readonly = true;
        if (type == 3) {
            readonly = false;
        }
        // Set readonly for inputs and textareas
        $(".service input, .service textarea").prop("readonly", readonly);
        // For selects, simulate readonly using Bootstrap styling and pointer-events
        $(".service select")
            .toggleClass("bg-light text-muted", readonly)
            .css("pointer-events", readonly ? "none" : "auto");
    }

    updateDecimal() {
        const decimal = $('input[name=measureDecimal]');
        const zeroValues = [2, 4];
        const measureID = parseInt($('select[name=measureID]').val()) || 0;
        decimal.val(zeroValues.includes(measureID) ? 0 : 2);
    }

    setAction(disabled) {
        $("[name=actionPrice]").prop("readonly", disabled);
        $("[name=actionTerminationType]").prop("readonly", disabled);
        $("[name=actionTerminationAmount]").prop("readonly", disabled);
        $("[name=actionStartDate]").prop("readonly", disabled);
        $("[name=actionTerminationDate]").prop("readonly", disabled);

        if (disabled) {
            $("[name=actionTerminationAmount]").closest("div").hide();
            $("[name=actionStartDate]").closest("div").hide();
            $("[name=actionTerminationDate]").closest("div").hide();
        }
    }

    setPriceType() {
        if ($("[name=priceType]").val() == 1) {
            $("[name=saleMargin]")
                .prop("readonly", true)
                .addClass("readonly");
            $("[name=salePriceWithoutFees]")
                .prop("readonly", false)
                .removeClass("readonly");
        } else {
            $("[name=saleMargin]")
                .prop("readonly", false)
                .removeClass("readonly");
            $("[name=salePriceWithoutFees]")
                .prop("readonly", true)
                .addClass("readonly");
        }
    }

    bindPriceEvents() {
        $("[name=feeRecycle], [name=purchasePrice], [name=feeCopyright], [name=salePriceWithoutFees], [name=salePriceTotal], [name=saleMargin]")
            .on("keyup", function(e) {
                var el = $(e.target);
                if (parseFloat(el.val()) !== parseFloat(el.prop("defaultValue"))) {
                    el.addClass("changed");
                } else {
                    el.removeClass("changed");
                }
                var purchasePrice = parseFloat($("[name=purchasePrice]").val()) || 0;
                var feeRecycle = parseFloat($("[name=feeRecycle]").val()) || 0;
                var feeCopyright = parseFloat($("[name=feeCopyright]").val()) || 0;
                var saleMargin = parseFloat($("[name=saleMargin]").val()) || 0;
                var salePrice = 0;
                var salePriceWithoutFees = parseFloat($("[name=salePriceWithoutFees]").val()) || 0;
                var salePriceTotal = parseFloat($("[name=salePriceTotal]").val()) || 0;

                // Always compute the base sale price without fees from purchasePrice and saleMargin
                var computedSalePriceWithoutFees = purchasePrice * (1 + saleMargin / 100);
                if (e.target.name !== "salePriceWithoutFees") {
                    salePriceWithoutFees = computedSalePriceWithoutFees;
                }

                var rate = $("#frmcard-taxVatSaleID option:selected").data("rate");
                if (rate === undefined) {
                    rate = 0;
                }
                var taxRate = (rate / 100) + 1;
                if (e.target.name === "salePriceTotal") {
                    salePrice = parseFloat((salePriceTotal / taxRate).toFixed(4));
                    salePriceWithoutFees = parseFloat((salePrice - feeRecycle - feeCopyright).toFixed(4));
                } else {
                    salePrice = parseFloat((salePriceWithoutFees + feeRecycle + feeCopyright).toFixed(4));
                    salePriceTotal = parseFloat((salePrice * taxRate).toFixed(4));
                }

                if (e.target.name !== "saleMargin" && purchasePrice > 0) {
                    saleMargin = parseFloat((((salePriceWithoutFees - purchasePrice) / purchasePrice) * 100).toFixed(2));
                }

                $("[name=salePriceTotal]").val(salePriceTotal);
                $("[name=salePrice]").val(salePrice);
                $("[name=salePriceWithoutFees]").val(salePriceWithoutFees);
                $("[name=saleMargin]").val(saleMargin);
            });
    }

}

class Documents {
    init() {
        const targetNode = document.querySelector('#snippet-catalogEditTabs-tabContent-doklady-tabControl-grid-grid-tbody');
        if (!targetNode) return;
        const totalCountElement = document.getElementById('totalCount');
        const config = { childList: true, subtree: true };
        const observer = new MutationObserver(() => {
            let totalSum = 0;
            const rows = targetNode.querySelectorAll('tr');
            rows.forEach(row => {
                const cells = row.querySelectorAll('td');
                if (cells.length >= 6) {
                    let value = parseFloat(cells[5].textContent.trim());
                    if (!isNaN(value)) {
                        totalSum += value;
                    }
                }
            });
            if (totalCountElement) {
                totalCountElement.textContent = totalSum;
            }
        });
        observer.observe(targetNode, config);
    }
}

class Items {
    init() {
        $(document)
            .off('click', '.expand-group')
            .on('click', '.expand-group', function() {
                const $icon = $(this).find('i.bi');
                $(this).nextUntil('.expand-group', '.expand-group-item').toggleClass('d-none');
                if ($icon.hasClass('bi-plus-square')) {
                    $icon.removeClass('bi-plus-square').addClass('bi-dash-square');
                } else {
                    $icon.removeClass('bi-dash-square').addClass('bi-plus-square');
                }
            });
    }
}


