import naja from "naja";

document.addEventListener('DOMContentLoaded', function () {

    init();
    naja.addEventListener('complete', () => {
        init();
    });

    function getCheckboxStates(e, checkboxes) {
        const states = Array.from(checkboxes).map(checkbox => {
            return {
                [checkbox.name]: checkbox.checked ? true : false
            };
        });

        naja.makeRequest('POST', e.target.getAttribute('data-save-filter-url'), {
            values: states
        });

        return states;
    }

    // Přidá event listener na každý checkbox, který vyvolá getCheckboxStates po kliknutí
    function init() {
        const checkboxes = document.querySelectorAll('input.invoiceFilterBtn');

        checkboxes.forEach(checkbox => {
            checkbox.addEventListener('click', (e) => {
                getCheckboxStates(e, checkboxes);
            });
        });
    }


});

